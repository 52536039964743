<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="white"
      @click="showDialog = true"
    >
      <v-icon
        left
      >
        mdi-exit-to-app
      </v-icon>
      {{ $t('title.logout') }}
    </v-btn>
    <confirmation-dialog
      :title="this.$t('title.logout')"
      :body="this.$t('message.logout')"
      :show-dialog.sync="showDialog"
      :store-function-name="'dialogs/setShowLogoutDialog'"
      :action="logout"
    />
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import CookieHelper from '../../../../helpers/CookieHelper'

  export default {
    name: 'DashboardCoreAppBar',
    components: {
      ConfirmationDialog: () => import('../../../pages/dialog/ConfirmationDialog'),
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      showDialog: false,
    }),

    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      logout (e) {
        e.preventDefault()
        CookieHelper.unsetCookie('user')
        this.$router.push('/pages/login')
      },
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
    },
  }
</script>
